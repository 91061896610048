import request from 'axios';
import {Environment} from "./Environment";

export class HomeAPI {

    static list = () => {

        const apiCompletionPromise = request({
            method: 'get',
            url: `${Environment.apiUrl}/homes/`,
        });

        return apiCompletionPromise;
    };

    static get = (homeId) => {

        const apiCompletionPromise = request({
            method: 'get',
            url: `${Environment.apiUrl}/homes/${homeId}`,
        });

        return apiCompletionPromise;
    };

    static randomHomes = () => {

        const apiCompletionPromise = request({
            method: 'get',
            url: `${Environment.apiUrl}/homes/random`,
        });

        return apiCompletionPromise;
    };

    static slugify = (text) => {
        return text
            .trim()
            .toLowerCase()
            .replace(/[^\w /-]+/g, '')
            .trim()
            .replace(/ +/g, '-')
            .replace(/\//g, '-')
            .replace(/-{2,}/g, '-')
            ;
    }


}
