import React from 'react';
import PropTypes from 'prop-types';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './RangeSliderComponents'
import {Utils} from "../services/Utils"; // example render components - source below

const sliderStyle = {
    position: 'relative',
    width: '100%',
}


class RangeSlider extends React.Component {

    constructor(props) {
        super(props);

        const { domain, values } = props;
        this.state = {
            domain,
            values: values.slice(),
            update: values.slice(),
            reversed: false,
        };
    }


    onUpdate = update => {
        this.setState({ update });

        const { onUpdate, label } = this.props;

        onUpdate(label, update);
    };

    onChange = values => {
        this.setState({ values })
    }

    render() {
        const {
            state: { domain, values, reversed, update },
        } = this;

        const { label } = this.props;

        return (
            <div style={{ height: 150, width: '100%' }} className="text-center">
                <h3>Filter by {Utils.prettifyKeys(label)}
                <br/>
                {Utils.numberWithCommas(update[0])}{' '}-{' '}{Utils.numberWithCommas(update[1])}
                </h3>
                <br/>
                <Slider
                    mode={2}
                    step={1}
                    domain={domain}
                    reversed={reversed}
                    rootStyle={sliderStyle}
                    onUpdate={this.onUpdate}
                    onChange={this.onChange}
                    values={values}
                >
                    <Rail>
                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                    </Rail>
                    <Handles>
                        {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        domain={domain}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Handles>
                    <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                    <Ticks count={10}>
                        {({ ticks }) => (
                            <div className="slider-ticks">
                                {ticks.map(tick => (
                                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                                ))}
                            </div>
                        )}
                    </Ticks>
                </Slider>
            </div>
        )
    }
}

RangeSlider.defaultProps = {
    label: 'Default',
    domain: [200, 500],
    values: [250, 350],
    onUpdate: (label, values) => {},
};

RangeSlider.propTypes = {
    label: PropTypes.string,
    domain: PropTypes.array,
    values: PropTypes.array,
    onUpdate: PropTypes.func
};

export default RangeSlider