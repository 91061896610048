import {openDB} from 'idb'
import {Utils} from "./Utils";
export class DatabaseHelper {

    static SEARCH_RESULTS_TABLE_NAME = 'searchResults';

    static connect() {
        //check for support
        if (!('indexedDB' in window)) {
            const error = 'This browser doesn\'t support IndexedDB';
            console.log(error);
            return Promise.reject(error);
        }

        return openDB('homes-like-this-database', 2, {
            upgrade(db, oldVersion, newVersion, transaction) {
                console.log('making a new object store');
                console.log({db, oldVersion, newVersion, transaction});
                if (!db.objectStoreNames.contains('searchResults')) {
                    db.createObjectStore('searchResults', {keyPath: 'id', autoIncrement: true});
                }
            },
        });
    }

    static saveRecentSearch(data) {

        return DatabaseHelper
            .connect()
            .then(db=> {
                const item = {
                    ...data,
                    created: new Date().getTime(),
                    id: Utils.getRandomString(),
                };
                console.log('saveRecentSearch', db);
                return db.put(DatabaseHelper.SEARCH_RESULTS_TABLE_NAME, item);
            })

    }

    static getRecentSearches() {
        return DatabaseHelper
            .connect()
            .then(db=> {
                console.log({db});
                return db.getAll(DatabaseHelper.SEARCH_RESULTS_TABLE_NAME);
            })
    }


    static getRecentSearch(searchId) {
        console.log('getRecentSearch');
        return DatabaseHelper
            .connect()
            .then(db=> {
                console.log({db});
                return db.get(DatabaseHelper.SEARCH_RESULTS_TABLE_NAME, searchId);
            })
    }

}