import PropTypes from 'prop-types';


export const HomePropType = PropTypes.shape({
    id: PropTypes.oneOfType(
        [PropTypes.string,
        PropTypes.number,
        ]
    ),
    images: PropTypes.shape({
        number: PropTypes.shape({
            URL: PropTypes.string,
            ImageID: PropTypes.string,
            SequenceId: PropTypes.number,
        })
    }),
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    square_foot: PropTypes.oneOfType(
        [PropTypes.string,
         PropTypes.number,
        ]
    ),
    sold_price: PropTypes.number.isRequired,
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            score: PropTypes.number,
            labels: PropTypes.string,
        })
    ),
    HomeId: PropTypes.string,
    address: PropTypes.string.isRequired,
    url: PropTypes.string,
});
