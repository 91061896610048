import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import 'antd/dist/antd.css';
import LandingPage from "./scenes/LandingPage/LandingPage";
import HomesList from "./scenes/Home/HomesList";
import HomeDetail from "./scenes/Home/HomeDetail";
import About from "./scenes/About";

function Footer() {

    return (
        <footer>
            <hr/>
            <div className="text-center my-5">
                Built by <a href="https://tech.atila.ca" target="_blank"
                            rel="noopener noreferrer">Atila Tech</a>
            </div>

        </footer>
    )
}

function BasicExample() {
    return (
        <Router>
            <div>
                <Navbar/>
                <Route exact path="/" component={LandingPage} />
                <Route path="/homes" component={HomesList} />
                <Route path="/home/:addressSlug/:homeId" component={HomeDetail} />
                <Route path="/home/:addressSlug/:homeId" component={HomeDetail} />
                <Route path="/about" component={About} />
                <Footer />
            </div>
        </Router>
    );
}

export default BasicExample;
