import React from 'react';
import {Row, Col, Menu, Icon, Tag} from 'antd';
import {Link} from "react-router-dom";
import './Navbar.scss'
import {DatabaseHelper} from "../../services/DatabaseHelper";
import moment from "moment";
const { SubMenu } = Menu;
class Navbar extends React.Component {
    state = {
        recentSearches: [],
        menuMode: 'horizontal',
    };

    componentDidMount() {
     this.getPastSearches();
    }

    getPastSearches() {
        DatabaseHelper
            .getRecentSearches()
            .then(recentSearches=> {
                console.log({recentSearches});
                this.setState({recentSearches});
            });
    }

    render() {
        let { recentSearches } = this.state;

        recentSearches = recentSearches.sort((a, b) => {
            return b.created - a.created;
        }).slice(0, 5);

        console.log({recentSearches});

        const menu = (
            <Menu mode="horizontal" id="nav" key="nav">
                <Menu.Item key="about">
                    <Link className="pt-2 pr-4 px-xl-3" to="/about">
                        <span className="navbar-text-links">About</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="install-chrome-extension">
                    <a href="https://chrome.google.com/webstore/detail/homes-like-this/iicnnghecnmpgchcilcncbaniakgapel?hl=en&authuser=0"
                       target="_blank"
                       rel="noopener noreferrer">

                        <span className="navbar-text-links">
                            <Tag color="green">New</Tag>Install Chrome Extension
                        </span>
                    </a>
                </Menu.Item>
                <SubMenu
                    key="user"
                    title={<Icon type="user" />}
                >

                    <h3 className="container">Recent Searches</h3>
                    {recentSearches.map(recentSearch => (
                            <Menu.Item key={`${recentSearch.id}`}>
                                <Link to={`/?searchId=${recentSearch.id}`}>
                                    <small>
                                        {moment(recentSearch.created).format('MMMM Do, h:mm a')} |
                                    </small>
                                    {recentSearch.imageFeatureLabels && recentSearch.imageFeatureLabels[0].labels}
                                </Link>
                            </Menu.Item>
                    ))}
                </SubMenu>
            </Menu>
        );

        return (
            <div id="header"
                 className="header mx-3 mx-lg-5 mt-3">
                <Row>
                    <Col xxl={4} xl={5} lg={8} md={8} sm={8} xs={0}>
                        <h2 id="logo" className="text-center4">
                            <Link to="/">
                                <span><strong>Homes</strong>LikeThis</span>
                            </Link>
                        </h2>
                    </Col>
                    <Col xxl={20} xl={19} lg={16} md={16} sm={16} xs={0}>
                        <div className="header-meta">
                            <div id="menu">{menu}</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={24}>
                        <h2 id="logo"
                            className="text-center ant-col-xs-24">
                            <Link to="/">
                            <span><strong>Homes</strong>LikeThis</span>
                            </Link>
                        </h2>
                    </Col>
                    <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={24}
                         className="ml-4">
                        <div className="header-meta">
                            <div id="menu">
                                {menu}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }


}

export default Navbar;
