import React from 'react';

const HomeMap = ({home}) => {

    const {latitude, longitude } = home;
    const mapImage = 'https://maps.googleapis.com/maps/api/staticmap' +
        `?center=${latitude},${longitude}&markers=color:red%7Clabel:%7C${latitude},${longitude}` +
        '&zoom=17&size=800x400&maptype=roadmap' +
        '&key=AIzaSyD0wKS2bvkkChs1VAdS8Bxqjn3ltfWa7bM';
    return (
        <img
            className="d-block w-100"
            src={mapImage}
            alt={'HomeMap item'}
        />
    );
};

export default HomeMap;