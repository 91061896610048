export class Utils {

    static getRandomString() {
        // https://gist.github.com/gordonbrander/2230317
        let id = '';

        for (let i =0; i< 4; i++) {
            id += Math.random().toString(36).substr(2, 8);
        }

        return id;
    }

    // https://github.com/ademidun/atila-angular/blob/9cb6dbbe8e2c1f0f4d7812740c1a06c6d811e331/src/app/_shared/utils.ts#L3
    static prettifyKeys(rawKey) {

        return Utils.toTitleCase(rawKey.replace(/_/g, ' ' ));

    }

    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

// https://github.com/ademidun/atila-angular/blob/9cb6dbbe8e2c1f0f4d7812740c1a06c6d811e331/src/app/_shared/utils.ts#L11
    static toTitleCase(str) {
        var i, j, lowers, uppers;
        str = str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

        // Certain minor words should be left lowercase unless
        // they are the first or last words in the string
        lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
            'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
        for (i = 0, j = lowers.length; i < j; i++) {
            str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'),
                function(txt) {
                    return txt.toLowerCase();
                });
        }


        // Certain words such as initialisms or acronyms should be left uppercase
        uppers = ['Id', 'Tv'];
        for (i = 0, j = uppers.length; i < j; i++) {
            str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'),
                uppers[i].toUpperCase());
        }

        return str;
    }
}