import React from 'react';
import HomeCard from "../HomeSearch/HomeCard/HomeCard";
import BarLoader from 'react-spinners/BarLoader';
import {Row, Col, Icon} from "antd";
import {HomeAPI} from "../../services/HomeAPI";
import {withRouter} from "react-router-dom";
import HomeMap from "./HomeMap";

class HomeDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            prevHomeId: null,
            home: null,
            similarHomes: null,
            isLoadingHomeDetail: false,
            isLoadingSimilarHomes: false,
        };
    }

    componentDidMount() {
        this.getHome();
    }

    static getDerivedStateFromProps(props, state) {
        // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#fetching-external-data-when-props-change
        // Store prevSlug in state so we can compare when props change.
        // Clear out previously-loaded data (so we don't render stale stuff).
        const { prevHomeId } = state;
        const {  match : { params : { homeId }} } = props;

        if (prevHomeId !== homeId) {
            return {
                ...state,
                prevHomeId: homeId,
                home: null,
                similarHomes: null,
                isLoadingHomeDetail: false,
                isLoadingSimilarHomes: false,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const { prevHomeId } = this.state;

        if (prevHomeId !== prevState.prevHomeId) {
            this.getHome();
        }
    }


    getHome() {
        const {  match : { params : { homeId }} } = this.props;
        this.setState({isLoadingHomeDetail: true});

        HomeAPI.get(homeId)
            .then(res=> {
                console.log({ res });
                const { data: home } = res;
                this.setState({ home });

                HomeAPI.randomHomes()
                    .then(res=> {
                        console.log({ res });
                        const { data: similarHomes } = res;
                        this.setState({ similarHomes });
                    })
                    .catch(err=>{
                        console.log({ err });
                    })
                    .finally(()=>{
                        this.setState({isLoadingSimilarHomes: false});
                    })
            })
            .catch(err=>{
                console.log({ err });
            })
            .finally(()=>{
                this.setState({isLoadingHomeDetail: false});
            })
    }

    render () {

        const { home, similarHomes, isLoadingHomeDetail, isLoadingSimilarHomes } = this.state;

        return (
            <div className="container HomeDetail">
                <Row>
                    <Col span={24}>

                        {isLoadingHomeDetail &&
                        <div>
                            <h5>Loading User Home...</h5>
                            <BarLoader  color={'#0b9ef5'} height={7} width={300}/>
                        </div>
                        }

                        { home &&
                        <React.Fragment>
                            <h2 className="text-center">
                                {home.address}
                            </h2>
                            <Row gutter={16}>
                                <Col key={home.id} sm={24} md={12} className="main-home">
                                    <HomeCard home={home}/>
                                </Col>
                                <Col key={home.id} sm={24} md={12}>
                                    <HomeMap home={home}/>
                                    <hr/>
                                    <div style={{ fontSize: 'larger' }}>
                                        <div>
                                            <div>

                                                <img
                                                    src="https://i.imgur.com/CHOXdi4.jpg"
                                                    alt="Chalo Garcia" style={{height: '250px', borderRadius: '50%'}} />
                                                <div><h4>Chalo Garcia</h4><h5
                                                >Sales Representative</h5></div>
                                            </div>
                                        </div>
                                        <Icon type="phone" />{' '}
                                        Schedule a Listing by calling{' '}
                                        <a href="tel:519-123-4567">519-123-4567</a>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                        }
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col span={24}>

                        {isLoadingSimilarHomes &&
                        <div>
                            <h5>Loading Similar Homes...</h5>
                            <BarLoader  color={'#0b9ef5'} height={7} width={300}/>
                        </div>
                        }

                        { similarHomes &&
                        <React.Fragment>
                            <h2 className="text-center">
                                <span style={{textDecoration: 'line-through'}}>Similar</span> Random
                                Homes</h2>

                            <Row gutter={16}>
                                {similarHomes.map(home =>(
                                    <Col key={home.id} sm={24} md={12} lg={8}>
                                        <HomeCard home={home}/>
                                    </Col>
                                ))}
                            </Row>
                        </React.Fragment>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(HomeDetail);
