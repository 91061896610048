import React from 'react';
import {enquireScreen} from 'enquire-js';
import './LandingPage.scss';
import './Reponsive.scss';
import HelmetSeo, {defaultSeoContent} from "../../components/HelmetSeo";
import QueueAnim from "rc-queue-anim";
import HomeSearch from "../HomeSearch/HomeSearch";

let isMobile = false;
enquireScreen((b) => {
    isMobile = b;
});

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
    }

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b,
            });
        });
    }
    render() {
        return (
                <div className="page-wrapper home">
                    <HelmetSeo content={defaultSeoContent}/>
                    <QueueAnim type="alpha" delay={150}>
                        <h1 key="h1" className="mt-sm-5">
                            Homes Like This
                        </h1>
                        <h2 key="h2" className="text-center">
                            Find Homes Visually
                            <span role="img" aria-label="house emoji">
                🏡
              </span>
                        </h2>
                        <HomeSearch key="HomeSearch" />
                    </QueueAnim>
                </div>
        );
    }
}
export default LandingPage;
