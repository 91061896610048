import React from 'react';

import sqrFootIcon from '../assets/sqrFootIcon.svg';
import bathIcon from '../assets/bathIcon.svg';
import bedIcon from '../assets/bedIcon.svg';
import {HomePropType} from "../../../shared/HomePropType";
import './HomeCard.scss';
import HomeImagesCarousel from "./HomeImagesCarousel";
import {Link} from "react-router-dom";
import {HomeAPI} from "../../../services/HomeAPI";

const HomeCard = ({home}) => {

    let imagesArray = [];
    if (home.url) {
        imagesArray.push(home.url)
    }

    Object.keys(home.images).forEach(function(key) {
        imagesArray.push(home.images[key].Url);
    });

    //home_id is used if HomeCard received a HomeImage as the 'home' prop;

    const homeId = home.home_id || home.id;

    return (
        <div className="HomeCard card mb-3">
            {
                imagesArray &&
                <HomeImagesCarousel images={imagesArray} />
            }
            <div className="card-body">
                <h5 className="card-title">
                    <Link to={`/home/${HomeAPI.slugify(home.address)}/${homeId}`}>
                        {home.address}
                    </Link>
                </h5>
                <div className="block pt-2 card-text pb-2">
                    <div className="py-1 text-success">

                        {home.sold_price &&

                        <span>$
                        {home.sold_price.toLocaleString(
                            undefined,
                            {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            }
                        )}
                        </span>
                        }

                    </div>
                    <div className="py-1">
                        <img className="pr-2" src={sqrFootIcon} alt="Square Foot" aria-hidden="true" />
                        {home.square_foot &&
                        home.square_foot.toLocaleString(
                            undefined,
                            { minimumFractionDigits: 0,
                                maximumFractionDigits: 0}
                        )} SQ. FT
                    </div>
                    <div className="py-1">
                        <img className="pr-2" src={bedIcon} alt="BATHS" aria-hidden="true" />
                        {home.bathrooms} BATHS
                    </div>
                    <div className="py-1">
                        <img className="pr-2" src={bathIcon} alt="BEDS" aria-hidden="true" />
                        {home.bedrooms} BEDS
                    </div>
                </div>
                <button className="btn btn-primary">View Home</button>
            </div>
        </div>
    );
};

HomeCard.propTypes = {
    home: HomePropType
};

export default HomeCard;



