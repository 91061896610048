import React from 'react';
import './HomeSearch.scss';
import {ImageSearchAPI} from "../../services/ImageSearchAPI";
import HomeCard from "./HomeCard/HomeCard";
import BarLoader from 'react-spinners/BarLoader';
import {Button, Upload, message, Progress, Row, Col, Tag, Collapse} from "antd";
import {SAMPLE_HOME_IMAGE_RESULTS} from "../../shared/HomeData";
import {DatabaseHelper} from "../../services/DatabaseHelper";
import {withRouter} from "react-router-dom";
import RangeSlider from "../../components/RangeSlider";
const { Panel } = Collapse;
const { Dragger } = Upload;

const filterTypes = [
    {
        label: 'bedrooms',
        domain: [0,7],
        values: [0, 5],
    },
    {
        label: 'bathrooms',
        domain: [0,10],
        values: [0, 5],
    },
    {
        label: 'sold_price',
        domain: [0,900000],
        values: [200000, 700000],
    },
    {
        label: 'square_foot',
        domain: [0,3000],
        values: [200, 2200],
    },
];

class HomeSearch extends React.Component {
    constructor(props) {
        super(props);

        const filters = {};

        filterTypes.forEach(filterType => {
            filters[filterType.label] = {min: filterType.domain[0], max: filterType.domain[1]}
        });

        this.state = {
            userImageUrl: 'https://s3.amazonaws.com/images.homeslikemine.com/n5EUpoXGR16GEEEaqjASWA.jpg',
            homeImageResults: SAMPLE_HOME_IMAGE_RESULTS,
            homeImageResultsFiltered: null,
            isLoadingImageSearchResult: false,
            imageUploadProgress: null,
            isLoadingImageFeaturesAndLabels: null,
            imageFeatureVectors: null,
            imageFeatureLabels: null,
            searchId: null,
            prevSearchId: null,
            filters
        };
    }

    componentDidMount() {
        const {
            location : { search },
        } = this.props;

        const params = new URLSearchParams(search);
        const searchId = params.get('searchId');
        const userImageUrlSearchParams = params.get('url');
        const autoSubmitImage = params.get('submit');
        const userImageUrlLocalStorage = localStorage.getItem('userImageUrl');
        

        if (userImageUrlSearchParams) {
            this.setState({
                userImageUrl:  userImageUrlSearchParams
            }, () => {
                if (autoSubmitImage) {
                    this.handleImageSubmit();
                }
            });
        } 
        else {
                if (userImageUrlLocalStorage) {
                this.setState({ userImageUrl:  userImageUrlLocalStorage});
            }
            if (searchId) {
                this.setState({searchId});
                this.loadPastSearch(searchId);
            }
        }



    }

    loadPastSearch(searchId) {
        console.log('loadPastSearch');
        console.log({searchId});

        DatabaseHelper
            .getRecentSearch(searchId)
            .then(recentSearch=> {
                console.log({recentSearch});
                const { imageFeatureLabels, userImageUrl, homeImageResults } = recentSearch;
                this.setState({ imageFeatureLabels, userImageUrl, homeImageResults });
                this.setState({ prevSearchId: searchId });
            });
    }


    componentDidUpdate(prevProps, prevState) {
        const { prevSearchId } = this.state;
        const {
            location : { search },
        } = this.props;

        const params = new URLSearchParams(search);
        const searchId = params.get('searchId');

        console.log('componentDidUpdate');
        console.log({searchId, prevSearchId});

        if (searchId && searchId !== prevSearchId) {
            this.loadPastSearch(searchId);
        }
    }

    handleChangeImageUrl = event => {
        event.preventDefault();
        this.setState({ userImageUrl: event.target.value });
    };

    handleImageSubmit = event => {

        if(event && event.preventDefault){
            event.preventDefault();
        }

        this.setState({ isLoadingImageFeaturesAndLabels: true });
        this.setState({ homeImageResults: [], imageFeatureLabels: null });

        const {userImageUrl} = this.state;
        localStorage.setItem('userImageUrl', userImageUrl);
        let imageResults = {};
        ImageSearchAPI.getImageFeatures({url: userImageUrl})
            .then(res => {
                console.log({ res});
                this.setState({ isLoadingImageFeaturesAndLabels: false });
                this.setState({ isLoadingImageSearchResult: true });
                const { feature_vectors:  imageFeatureVectors, feature_labels: imageFeatureLabels } = res.data;
                this.setState({ imageFeatureVectors, imageFeatureLabels });
                this.getSimilarImages(imageFeatureVectors, imageResults);
            })
            .catch(err => {
                console.log({ err});
                this.setState({ isLoadingImageFeaturesAndLabels: false });
            })
            .finally(() => {
                console.log('finished!');
                this.setState({ isLoadingImageFeaturesAndLabels: false });
            });
    };

    getSimilarImages = (imageFeatureVectors, imageResults) => {

        ImageSearchAPI.getSimilarImages({feature_vectors: imageFeatureVectors})
            .then(similarImagesResponse => {
                console.log({ similarImagesResponse });
                const { similar_images } = similarImagesResponse.data;

                if (imageResults) {
                    this.setState({ homeImageResults: similar_images });
                    this.setState({ isLoadingImageSearchResult: false });
                    this.saveSearchResults();
                }
            })
            .catch(err => {
                console.log({ err});
                this.setState({ isLoadingImageSearchResult: false });
            })
    };

    updateFilter = (label, values) => {
        console.log({label, values});
        const { filters } = this.state;
        filters[label] = {min: values[0], max: values[1]};

        this.setState({
            filters
        }, () => {
            this.filterHomes()
        });

    };

    filterHomes = () => {
            const { homeImageResults, filters } = this.state;
            let homeImageResultsFiltered = homeImageResults.slice();

            for (const filterType in filters) {
                if (filters.hasOwnProperty(filterType)) {
                    homeImageResultsFiltered = homeImageResultsFiltered.filter(home=>(
                        (home[filterType] >= filters[filterType].min && home[filterType] <= filters[filterType].max)
                    ))
                }
            }

            this.setState({homeImageResultsFiltered});

            console.log({ homeImageResults, homeImageResultsFiltered });
    } ;

    saveSearchResults = () => {

        const { imageFeatureLabels, userImageUrl, homeImageResults } = this.state;

        DatabaseHelper
            .saveRecentSearch({ imageFeatureLabels, userImageUrl, homeImageResults })
            .then(res=> {
                console.log({res});
            });
    };

    // https://github.com/react-component/upload#customrequest
    handleImageUpload = ({file}) => {
        const { uploadTask, uploadRef} = ImageSearchAPI.uploadUserHomeImage({file});

        uploadTask.on(ImageSearchAPI.FIREBASE_STATE_CHANGED,  (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log({progress});
            this.setState({ imageUploadProgress: progress });
        }, (error) => {
            console.log({error})
        },  () => {
            uploadRef.getDownloadURL().then(userImageUrl => {
                this.setState({ userImageUrl });
                message.success(`${file.name} file uploaded successfully.`);
                this.setState({ imageUploadProgress: null });
            });
        });
    };

    render () {

        const { userImageUrl, homeImageResults,
            isLoadingImageSearchResult, imageUploadProgress,
            isLoadingImageFeaturesAndLabels, imageFeatureLabels, homeImageResultsFiltered } = this.state;

        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            accept: 'image/*',
            customRequest: this.handleImageUpload,
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        const customBarLoader = (<div className="text-center mb-5">
            <h5>{isLoadingImageFeaturesAndLabels ? "Loading image labels" : "Loading Similar homes"}</h5>
            <BarLoader css="display: block; margin-left: auto; margin-right: auto;"
                       color={'#0b9ef5'} height={7} width={300}/>
        </div>);

        let resultsToDisplay = homeImageResults;

        if (homeImageResultsFiltered) {
            resultsToDisplay = homeImageResultsFiltered;
        }

        return (
            <div className="container">
                <Row gutter={16}>
                    <Col xs={24} md={8}>
                        <Dragger {...draggerProps}>
                            <p className="ant-upload-drag-icon" style={{fontSize: '40px'}}>
                            <span role="img" aria-label="house emoji">
                                🏡
                            </span>
                            </p>
                            <p className="ant-upload-text">Drag and drop an image of your home</p>
                            <p className="ant-upload-hint">
                                You can also click to select file or activate camera
                            </p>
                        </Dragger>
                        {imageUploadProgress &&
                        <div className="progress-div mt-4">
                            <Progress
                                type="circle"
                                strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                }}
                                percent={imageUploadProgress}
                            />
                        </div>
                        }
                        <form
                            className="row col-12 justify-content-center mt-3"
                            onSubmit={this.handleImageSubmit}>
                            <input type="text" className="col-12 form-control"
                                   onChange={this.handleChangeImageUrl}
                                   value={userImageUrl}
                                   placeholder="Or paste an image URL"/>
                            <Button
                                type="primary"
                                className="btn btn-primary col-md-6 mt-3"
                                onClick={this.handleImageSubmit}
                                disabled={isLoadingImageFeaturesAndLabels||isLoadingImageSearchResult}
                            > { isLoadingImageFeaturesAndLabels||isLoadingImageSearchResult ? "Loading": "Find Homes!"} 
                            </Button>
                            <br />

                        </form>
                    </Col>
                    <Col xs={24} md={16}>
                        {userImageUrl &&
                        (<div className="col-md-12 justify-content-center row">
                            <img className="user-image mt-3"
                                 alt="Home submitted by user"
                                 src={userImageUrl}/>
                            {
                                imageFeatureLabels && <div>
                                <p>
                                    Labels <br/>
                                    {imageFeatureLabels.map ((label) => (
                                        <Tag color="blue" key={label.labels}>
                                            {label.labels}
                                        </Tag>
                                    ))}
                                </p>
                            </div>
                            }
                            {isLoadingImageFeaturesAndLabels &&
                            customBarLoader
                            }
                        </div>)}
                    </Col>
                </Row>
                <hr/>
                <Row gutter={24}>
                    <Collapse>
                        <Panel header="Filters" key="1">
                            {filterTypes.map(filterType => (
                                <Col md={12} sm={24}>
                                    <RangeSlider domain={filterType.domain}
                                                 values={filterType.values}
                                                 onUpdate={this.updateFilter}
                                                 label={filterType.label} />
                                </Col>
                            ))}
                        </Panel>
                    </Collapse>


                </Row>
                <hr/>
                <Row>
                    <Col span={24}>

                        { homeImageResults &&
                            <h2 className="text-center">Results</h2>
                        }
                        {isLoadingImageFeaturesAndLabels &&
                            customBarLoader
                        }
                        {isLoadingImageSearchResult &&
                            customBarLoader
                        }
                        <Row gutter={16}>
                        {resultsToDisplay.map(home =>(
                            <Col key={home.id} sm={24} md={12} lg={8}>
                                <HomeCard home={home}/>
                            </Col>
                        ))}
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default withRouter(HomeSearch);
