import React from 'react';
import Carousel from "react-bootstrap/Carousel";

const HomeImagesCarousel = ({images}) => {
    return (
        <Carousel interval={null}>
            {
                images.map( (image, index) => (
                    <Carousel.Item key={`${image}-${index}`}>
                        <img
                            className="d-block"
                            src={image}
                            alt={'HomeImagesCarousel item'}
                            style={{
                                height: "auto",
                                maxHeight: "350px",
                                objectFit: "contain"
                            }}
                        />
                        {/*<Carousel.Caption>*/}
                        {/*    <h3>First slide label</h3>*/}
                        {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                        {/*</Carousel.Caption>*/}
                    </Carousel.Item>
                ))
            }
        </Carousel>
    );
};

export default HomeImagesCarousel;



