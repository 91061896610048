import React from "react";
import { Col, Row } from "antd";
import HelmetSeo from "../components/HelmetSeo";

const About = () => {

    const seoContent = {
        title: 'About Homes Like This - Find Homes Visually | 🏡',
        description: 'Tomiwa, Brian, and Forrest. The team that made Homes Like This',
        image: 'https://i.imgur.com/vMya80D.png',
        slug: 'about'
    };

    const teamMembers = [
        {
            first_name: "Tomiwa",
            last_name: "Ademidun",
            username: "tomiwa",
            position: "Founder",
            img_url: "https://i.imgur.com/CiXnyB8.png",
            description_1:
                "Tomiwa is a dual degree software engineering and business student at Ivey Business School," +
                " Western University.",
            description_2: "He enjoys playing soccer and is a big Arsenal F.C. fan.",
            link_type: "Linkedin",
            link_url: "https://www.linkedin.com/in/tademidun/",
        },
        {
            first_name: "Brian",
            last_name: "Lim",
            position: "Marketing",
            username: "Melissa",
            img_url: "https://i.imgur.com/g3VNKWF.jpg",
            description_1: "Melissa is a BMOS student at Western university.",
            link_type: "LinkedIn",
            link_url: "https://www.linkedin.com/in/brian-lim-49737013b/",
        },
        {
            first_name: "Forrest",
            last_name: "Lin",
            position: "Designer",
            username: null,
            img_url: "https://i.imgur.com/2fzMruL.jpg",
            description_1: "Emily Chen is a medical student at the University of Toronto.",
            link_type: "LinkedIn",
            link_url: "https://www.linkedin.com/in/forrest-lin-412a57142/",
        },
    ];

    return (
        <React.Fragment>
            <HelmetSeo content={seoContent} />
            <div className='container mt-3'>
                <h1>About</h1>
                <div>
                        <p>
                        Homes Like This is the first real estate website, that allows you to search for homes visually.
                        </p>
                        <p>

                        Think about it, most humans think visually, but computers think numerically. Most real estate websites
                        are built for computers not humans.
                        </p>
                        <p>

                        Homes Like This is the only real estate website that is specifically built for how humans think.
                        </p>

                    <p>
                        This is the final year software engineering design project created by{' '}
                        <a
                            href="https://www.linkedin.com/in/tademidun/"
                            className='social-link'
                            target='_blank'
                            rel='noopener noreferrer'
                        >

                            Tomiwa Ademidun
                        </a> {' '}(<a
                        href="https://tomiwa.ca/"
                        className='social-link'
                        target='_blank'
                        rel='noopener noreferrer'
                    >

                        tomiwa.ca
                    </a>),
                        <a
                            href="https://www.linkedin.com/in/tademidun/"
                            className='social-link'
                            target='_blank'
                            rel='noopener noreferrer'
                        >

                            Brian Lim
                        </a>,
                        and{' '}
                        <a
                            href="https://www.linkedin.com/in/tademidun/"
                            className='social-link'
                            target='_blank'
                            rel='noopener noreferrer'
                        >

                            Forrest Lin
                        </a> as part of the dual degree software engineering
                        and honour's business program at Ivey Business School and Western University.
                    </p>
                    <p>
                        We think this project could be really useful and want to make it available for
                        actual home buyers to use.
                        If interested in this or you have more questions about how we built this,
                        send an email to{' '}
                        <a href='mailto:ta@tomiwa.ca' target='_blank' rel='noopener noreferrer'>ta@tomiwa.ca</a>,
                        {' '}
                        <a
                            href="https://www.linkedin.com/in/tademidun/"
                            className='social-link'
                            target='_blank'
                            rel='noopener noreferrer'
                        >message Tomiwa on LinkedIn</a>{' '} or{' '}
                        <a href="http://twitter.com/tomiwa1a" target="_blank" rel='noopener noreferrer'>dm @tomiwa1a on
                        Twitter</a>.
                    </p>
                    <p>
                        Built by <a href="https://tech.atila.ca" target="_blank"
                                    rel="noopener noreferrer">Atila Tech</a>
                    </p>
                </div>
                <br />

                <div className='bg-white rounded shadow mb-3 p-3'>
                <img
                className='center-block'
                src="https://i.imgur.com/vMya80D.png"
                alt="Team Presentation, March 12 2020"
                style={{ width: "100%", height: "auto", borderRadius: "0%"}}

            />
                </div>
                <Row gutter={16}>
                    {teamMembers.map(teamMember => (
                        <Col md={8} sm={12} xs={24} className='my-3 center-block'>
                        <div className='bg-white rounded shadow mb-3 p-3' style={{minHeight: '475px'}}>
                            <img
                                className='center-block'
                                src={teamMember.img_url}
                                alt={teamMember.first_name}
                                style={{ width: "100%", height: "auto", borderRadius: "50%"}}

                            />
                            <h5 className='my-3 text-center'>
                                <strong>
                                    {teamMember.first_name} {teamMember.last_name}
                                </strong>
                            </h5>
                            <ul className='social my-2 text-center'>
                                <li className='list-inline-item'>
                                    <a
                                        href={teamMember.link_url}
                                        className='social-link'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        LinkedIn
                                    </a>
                                </li>
                            </ul>
                        </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <div className='container mt-3 text-center' style={{overflow: 'auto', border: 'solid .5px lightgray' }}>
                <h1>How It works</h1>
                <p>Watch our various presentations to see how it works</p>

                <br />
                <Row gutter={16}>
                    <Col span={24} className='my-3'>
                        <h3>
                            <a href="https://docs.google.com/presentation/d/1pyzPLb5neIv3cagYYxPTRtuKFpBQop4DxLCpYhGwEeA/edit?usp=sharing"
                            target="_blank" rel="noopener noreferrer">
                            Capstone Competition Slides
                            </a>
                        </h3>
                        <p>Make sure to watch with speaker's notes</p>
                        <iframe
                            src="https://docs.google.com/presentation/d/e/2PACX-1vQmzxh0TblZaCm8NkKSBR8bX7MYAV6dD0qqtoP-XUtCwNkOKzWW_QsQnkEdd5wm946atf_uIkImO3Ii/embed?start=false&loop=false&delayms=3000"
                            title="Capstone Competition Slides"
                            frameBorder="0" width="960" height="569" allowFullScreen="true" mozallowfullscreen="true"
                            webkitallowfullscreen="true" />
                    </Col>
                    <Col span={24} className='my-3'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/sip19JjRavk" frameBorder="0"
                                title="Homes Like This Demo"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen />
                    </Col>
                </Row>
            </div>
            <div className='container mt-3'>
                <h1>Credits</h1>
                <br />
                <Row gutter={16}>
                    <Col span={24} className='my-3'>
                    Image of realtor from Chalo Garcia: https://unsplash.com/photos/2mWKL_I70qk
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default About;
