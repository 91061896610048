import React from 'react';
import HomeCard from "../HomeSearch/HomeCard/HomeCard";
import BarLoader from 'react-spinners/BarLoader';
import {Row, Col} from "antd";
import {HomeAPI} from "../../services/HomeAPI";

class HomesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            homes: null,
            isLoadingHomes: false,
        };
    }

    componentDidMount() {
        this.getHomes();
    }

    getHomes() {
        this.setState({isLoadingHomes: true});
        HomeAPI.list()
            .then(res=> {
                console.log({ res });
                const { results: homes } = res.data;
                this.setState({ homes });
            })
            .catch(err=>{
                console.log({ err });
            })
            .finally(()=>{
                this.setState({isLoadingHomes: false});
            })
    }

    render () {

        const {homes, isLoadingHomes } = this.state;

        return (
            <div className="container">
                <Row>
                    <Col span={24}>

                        {isLoadingHomes &&
                        <div>
                            <h5>Loading Homes...</h5>
                            <BarLoader  color={'#0b9ef5'} height={7} width={300}/>
                        </div>
                        }

                        { homes &&
                            <React.Fragment>
                                <h2 className="text-center">Nearby Homes</h2>

                                <Row gutter={16}>
                                    {homes.map(home =>(
                                        <Col key={home.id} sm={24} md={12} lg={8}>
                                            <HomeCard home={home}/>
                                        </Col>
                                    ))}
                                </Row>
                            </React.Fragment>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HomesList;
