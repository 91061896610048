

export const SAMPLE_HOME_IMAGE_RESULTS = [
    {
        "id": "n5EUpoXGR16GEEEaqjASWA",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/n5EUpoXGR16GEEEaqjASWA.jpg",
        "address": "91 Evergreen Terrace SW, Calgary, AB T2Y 2R7, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/qAqYBYiAS5-Pv5WROt-CuQ.jpg",
                "ImageId": "qAqYBYiAS5-Pv5WROt-CuQ",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/n5EUpoXGR16GEEEaqjASWA.jpg",
                "ImageId": "n5EUpoXGR16GEEEaqjASWA",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/lJFG0esFSl-ymVsaKCSAvQ.jpg",
                "ImageId": "lJFG0esFSl-ymVsaKCSAvQ",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/ww_TJR7WRI-VsFKZztWs8w.jpg",
                "ImageId": "ww_TJR7WRI-VsFKZztWs8w",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/cBaAxhleTc2XVF5UB78jCA.jpg",
                "ImageId": "cBaAxhleTc2XVF5UB78jCA",
                "SequenceId": 5
            }
        },
        "bedrooms": 4,
        "bathrooms": 4,
        "square_foot": 2167,
        "sold_price": 655501,
        "labels": [
            {
                "score": 0.2901037335395813,
                "labels": "patio, terrace"
            },
            {
                "score": 0.2529788017272949,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.06765806674957275,
                "labels": "solar dish, solar collector, solar furnace"
            },
            {
                "score": 0.033402979373931885,
                "labels": "mobile home, manufactured home"
            },
            {
                "score": 0.02747858688235283,
                "labels": "lakeside, lakeshore"
            }
        ],
        "home_id": 97,
        "distance": 1
    },
    {
        "id": "qAqYBYiAS5-Pv5WROt-CuQ",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/qAqYBYiAS5-Pv5WROt-CuQ.jpg",
        "address": "91 Evergreen Terrace SW, Calgary, AB T2Y 2R7, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/qAqYBYiAS5-Pv5WROt-CuQ.jpg",
                "ImageId": "qAqYBYiAS5-Pv5WROt-CuQ",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/n5EUpoXGR16GEEEaqjASWA.jpg",
                "ImageId": "n5EUpoXGR16GEEEaqjASWA",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/lJFG0esFSl-ymVsaKCSAvQ.jpg",
                "ImageId": "lJFG0esFSl-ymVsaKCSAvQ",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/ww_TJR7WRI-VsFKZztWs8w.jpg",
                "ImageId": "ww_TJR7WRI-VsFKZztWs8w",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/cBaAxhleTc2XVF5UB78jCA.jpg",
                "ImageId": "cBaAxhleTc2XVF5UB78jCA",
                "SequenceId": 5
            }
        },
        "bedrooms": 4,
        "bathrooms": 4,
        "square_foot": 2167,
        "sold_price": 655501,
        "labels": [
            {
                "score": 0.38232165575027466,
                "labels": "patio, terrace"
            },
            {
                "score": 0.12750060856342316,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.04597296193242073,
                "labels": "mobile home, manufactured home"
            },
            {
                "score": 0.04450831934809685,
                "labels": "library"
            },
            {
                "score": 0.02607220970094204,
                "labels": "lakeside, lakeshore"
            }
        ],
        "home_id": 97,
        "distance": 0.9228
    },
    {
        "id": "PAwN_3Q-Sw-4lRyrqW-x0Q",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/PAwN_3Q-Sw-4lRyrqW-x0Q.jpg",
        "address": "89 Discovery Ridge Gardens SW, Calgary, AB T3H 5L7, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/PAwN_3Q-Sw-4lRyrqW-x0Q.jpg",
                "ImageId": "PAwN_3Q-Sw-4lRyrqW-x0Q",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/ypCatPQ1QyK_l9_jvahfxg.jpg",
                "ImageId": "ypCatPQ1QyK_l9_jvahfxg",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/Oo-6fmQeSdSxOWtz5_y6hw.jpg",
                "ImageId": "Oo-6fmQeSdSxOWtz5_y6hw",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/UOCXnzMqSlSCjSVtL91Fmw.jpg",
                "ImageId": "UOCXnzMqSlSCjSVtL91Fmw",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/4bsAwHaQTy6VfhJWi2fpbw.jpg",
                "ImageId": "4bsAwHaQTy6VfhJWi2fpbw",
                "SequenceId": 5
            }
        },
        "bedrooms": 4,
        "bathrooms": 4,
        "square_foot": 2344,
        "sold_price": 678800,
        "labels": [
            {
                "score": 0.24549508094787598,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.12354066222906113,
                "labels": "window screen"
            },
            {
                "score": 0.10750040411949158,
                "labels": "library"
            },
            {
                "score": 0.058414164930582047,
                "labels": "patio, terrace"
            },
            {
                "score": 0.04472238942980766,
                "labels": "solar dish, solar collector, solar furnace"
            }
        ],
        "home_id": 54,
        "distance": 0.8882
    },
    {
        "id": "HwXqBajxR0eq6GxV2s2Awg",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/HwXqBajxR0eq6GxV2s2Awg.jpg",
        "address": "8 Scanlon Green NW, Calgary, AB T3L 1L3, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/HwXqBajxR0eq6GxV2s2Awg.jpg",
                "ImageId": "HwXqBajxR0eq6GxV2s2Awg",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/r1_8q6H4SGuhVPvH1LYuHw.jpg",
                "ImageId": "r1_8q6H4SGuhVPvH1LYuHw",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/onhWqakHSPSWfTtIWFj7Tg.jpg",
                "ImageId": "onhWqakHSPSWfTtIWFj7Tg",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/s7E6y1TCQiKoNg-eHDy53g.jpg",
                "ImageId": "s7E6y1TCQiKoNg-eHDy53g",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/YY3T2y2QRaKTUV-OUG60EA.jpg",
                "ImageId": "YY3T2y2QRaKTUV-OUG60EA",
                "SequenceId": 5
            }
        },
        "bedrooms": 5,
        "bathrooms": 5,
        "square_foot": 1917,
        "sold_price": 489900,
        "labels": [
            {
                "score": 0.0965791568160057,
                "labels": "lakeside, lakeshore"
            },
            {
                "score": 0.08390626311302185,
                "labels": "patio, terrace"
            },
            {
                "score": 0.07975032925605774,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.07339784502983093,
                "labels": "library"
            },
            {
                "score": 0.04519657418131828,
                "labels": "tile roof"
            }
        ],
        "home_id": 57,
        "distance": 0.8877
    },
    {
        "id": "BPSOisraR6-nr44Q_qvACQ",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/BPSOisraR6-nr44Q_qvACQ.jpg",
        "address": "Kincora Heath, Calgary, AB T3R, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/BPSOisraR6-nr44Q_qvACQ.jpg",
                "ImageId": "BPSOisraR6-nr44Q_qvACQ",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/DIT4OiXaRBC0jaRw5TWBJw.jpg",
                "ImageId": "DIT4OiXaRBC0jaRw5TWBJw",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/lwFrJa9bTUGrgrV9AwDgPQ.jpg",
                "ImageId": "lwFrJa9bTUGrgrV9AwDgPQ",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/9DUKn2aOTv2Py4l7pYgzjQ.jpg",
                "ImageId": "9DUKn2aOTv2Py4l7pYgzjQ",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/rbuzgtDPTVmta_lPR9U7zw.jpg",
                "ImageId": "rbuzgtDPTVmta_lPR9U7zw",
                "SequenceId": 5
            }
        },
        "bedrooms": 3,
        "bathrooms": 3,
        "square_foot": 1155,
        "sold_price": 314900,
        "labels": [
            {
                "score": 0.37141168117523193,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.26719626784324646,
                "labels": "solar dish, solar collector, solar furnace"
            },
            {
                "score": 0.06550583988428116,
                "labels": "patio, terrace"
            },
            {
                "score": 0.031064748764038086,
                "labels": "window screen"
            },
            {
                "score": 0.013714390806853771,
                "labels": "pot, flowerpot"
            }
        ],
        "home_id": 183,
        "distance": 0.8851
    },
    {
        "id": "u0Ql4Q1wThSWHLH1R5Ge2w",
        "url": "https://s3.amazonaws.com/images.homeslikethis.ca/u0Ql4Q1wThSWHLH1R5Ge2w.jpg",
        "address": "123 BRIGHTONCREST MR SE",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/u0Ql4Q1wThSWHLH1R5Ge2w.jpg",
                "ImageId": "u0Ql4Q1wThSWHLH1R5Ge2w",
                "SequenceId": 1
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/qYJBqEp-Tamb2dXmbHXekg.jpg",
                "ImageId": "qYJBqEp-Tamb2dXmbHXekg",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/6xAvH3fiQcqFiKvBuP6qiA.jpg",
                "ImageId": "6xAvH3fiQcqFiKvBuP6qiA",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/9DbpAJnMS56cJHL0A52-FA.jpg",
                "ImageId": "9DbpAJnMS56cJHL0A52-FA",
                "SequenceId": 5
            }
        },
        "bedrooms": null,
        "bathrooms": null,
        "square_foot": 1810,
        "sold_price": 469900,
        "labels": [
            {
                "score": 0.3605859577655792,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.30580273270606995,
                "labels": "patio, terrace"
            },
            {
                "score": 0.04564525932073593,
                "labels": "solar dish, solar collector, solar furnace"
            },
            {
                "score": 0.03408098593354225,
                "labels": "rain barrel"
            },
            {
                "score": 0.02943725697696209,
                "labels": "window screen"
            }
        ],
        "home_id": 62,
        "distance": 0.8803
    },
    {
        "id": "WynlqFLrQnKVojhYsP2haQ",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/WynlqFLrQnKVojhYsP2haQ.jpg",
        "address": "52 Everridge Rd SW, Calgary, AB T2Y 4S8, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/WynlqFLrQnKVojhYsP2haQ.jpg",
                "ImageId": "WynlqFLrQnKVojhYsP2haQ",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/t2TeinRlStW1xA0mbZ4A4Q.jpg",
                "ImageId": "t2TeinRlStW1xA0mbZ4A4Q",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/P7R7v2z2TXGLusyrSQNZVg.jpg",
                "ImageId": "P7R7v2z2TXGLusyrSQNZVg",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/pL-jZzmOR6eReybBpyZQCw.jpg",
                "ImageId": "pL-jZzmOR6eReybBpyZQCw",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/zz97UB_ZTT63YYotzLfDTw.jpg",
                "ImageId": "zz97UB_ZTT63YYotzLfDTw",
                "SequenceId": 5
            }
        },
        "bedrooms": 3,
        "bathrooms": 3,
        "square_foot": 1316,
        "sold_price": 379900,
        "labels": [
            {
                "score": 0.3926224410533905,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.2054157704114914,
                "labels": "patio, terrace"
            },
            {
                "score": 0.08202268183231354,
                "labels": "mobile home, manufactured home"
            },
            {
                "score": 0.06065237894654274,
                "labels": "window screen"
            },
            {
                "score": 0.026801934465765953,
                "labels": "flagpole, flagstaff"
            }
        ],
        "home_id": 200,
        "distance": 0.8724
    },
    {
        "id": "t2TeinRlStW1xA0mbZ4A4Q",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/t2TeinRlStW1xA0mbZ4A4Q.jpg",
        "address": "52 Everridge Rd SW, Calgary, AB T2Y 4S8, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/WynlqFLrQnKVojhYsP2haQ.jpg",
                "ImageId": "WynlqFLrQnKVojhYsP2haQ",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/t2TeinRlStW1xA0mbZ4A4Q.jpg",
                "ImageId": "t2TeinRlStW1xA0mbZ4A4Q",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/P7R7v2z2TXGLusyrSQNZVg.jpg",
                "ImageId": "P7R7v2z2TXGLusyrSQNZVg",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/pL-jZzmOR6eReybBpyZQCw.jpg",
                "ImageId": "pL-jZzmOR6eReybBpyZQCw",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/zz97UB_ZTT63YYotzLfDTw.jpg",
                "ImageId": "zz97UB_ZTT63YYotzLfDTw",
                "SequenceId": 5
            }
        },
        "bedrooms": 3,
        "bathrooms": 3,
        "square_foot": 1316,
        "sold_price": 379900,
        "labels": [
            {
                "score": 0.5200377106666565,
                "labels": "patio, terrace"
            },
            {
                "score": 0.18041107058525085,
                "labels": "mobile home, manufactured home"
            },
            {
                "score": 0.054746635258197784,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.030729077756404877,
                "labels": "solar dish, solar collector, solar furnace"
            },
            {
                "score": 0.013431346043944359,
                "labels": "flagpole, flagstaff"
            }
        ],
        "home_id": 200,
        "distance": 0.8649
    },
    {
        "id": "zdKN363VRTuB9DIIL1zR4A",
        "url": "https://s3.amazonaws.com/images.homeslikethis.ca/zdKN363VRTuB9DIIL1zR4A.jpg",
        "address": "#125 1555 FALCONRIDGE DR NE",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/zdKN363VRTuB9DIIL1zR4A.jpg",
                "ImageId": "zdKN363VRTuB9DIIL1zR4A",
                "SequenceId": 1
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/wtK0wg9ETkqaQBs8DWC46A.jpg",
                "ImageId": "wtK0wg9ETkqaQBs8DWC46A",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/9IHmjjYqQ9myxrx95vEmGg.jpg",
                "ImageId": "9IHmjjYqQ9myxrx95vEmGg",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikethis.ca/R0UKm1AqQpGBr8qpwFkY3w.jpg",
                "ImageId": "R0UKm1AqQpGBr8qpwFkY3w",
                "SequenceId": 5
            }
        },
        "bedrooms": null,
        "bathrooms": null,
        "square_foot": 1095,
        "sold_price": 238000,
        "labels": [
            {
                "score": 0.30424752831459045,
                "labels": "patio, terrace"
            },
            {
                "score": 0.12588761746883392,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.11071176826953888,
                "labels": "solar dish, solar collector, solar furnace"
            },
            {
                "score": 0.05760423094034195,
                "labels": "mobile home, manufactured home"
            },
            {
                "score": 0.042172640562057495,
                "labels": "window screen"
            }
        ],
        "home_id": 243,
        "distance": 0.8645
    },
    {
        "id": "XDBgj1YHTEKF67O8P-7nuw",
        "url": "https://s3.amazonaws.com/images.homeslikemine.com/XDBgj1YHTEKF67O8P-7nuw.jpg",
        "address": "46 Country Hills Park NW, Calgary, AB T3K 5E1, Canada",
        "images": {
            "1": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/XDBgj1YHTEKF67O8P-7nuw.jpg",
                "ImageId": "XDBgj1YHTEKF67O8P-7nuw",
                "SequenceId": 1
            },
            "2": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/2AzxmrSxSjWjyf4DX2kQqQ.jpg",
                "ImageId": "2AzxmrSxSjWjyf4DX2kQqQ",
                "SequenceId": 2
            },
            "3": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/jo0DaiGvQE63NcD4lP5aww.jpg",
                "ImageId": "jo0DaiGvQE63NcD4lP5aww",
                "SequenceId": 3
            },
            "4": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/Cv0ulYVmR2isnSRntYKumg.jpg",
                "ImageId": "Cv0ulYVmR2isnSRntYKumg",
                "SequenceId": 4
            },
            "5": {
                "Url": "https://s3.amazonaws.com/images.homeslikemine.com/aokbsuBwQRerOl6KxE4QOg.jpg",
                "ImageId": "aokbsuBwQRerOl6KxE4QOg",
                "SequenceId": 5
            }
        },
        "bedrooms": 4,
        "bathrooms": 4,
        "square_foot": 1747,
        "sold_price": 509988,
        "labels": [
            {
                "score": 0.36719802021980286,
                "labels": "picket fence, paling"
            },
            {
                "score": 0.1318194717168808,
                "labels": "church, church building"
            },
            {
                "score": 0.08591484278440475,
                "labels": "patio, terrace"
            },
            {
                "score": 0.07504317164421082,
                "labels": "beacon, lighthouse, beacon light, pharos"
            },
            {
                "score": 0.029682569205760956,
                "labels": "rain barrel"
            }
        ],
        "home_id": 264,
        "distance": 0.8626
    }
];
