import request from 'axios';
import firebase from 'firebase/app';
import 'firebase/storage';
import {firebaseConfig} from "../firebase.config";
import {Environment} from "./Environment";
firebase.initializeApp(firebaseConfig);

export class ImageSearchAPI {

    static FIREBASE_STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;

    static getSimilarImages = (imageData) => {

        const apiCompletionPromise = request({
            method: 'post',
            url: `${Environment.apiUrl}/images/similar-images/`,
            data: imageData,
            });

        return apiCompletionPromise;
    };

    static getImageFeatures = (imageData) => {

        const apiCompletionPromise = request({
            method: 'post',
            url: `${Environment.apiUrl}/images/image-features/`,
            data: imageData,
            });

        return apiCompletionPromise;
    };

    static uploadUserHomeImage = (imageData) => {

        console.log({event: imageData});
        const { file } = imageData;
        const storage = firebase.storage();
        const storageRef = storage.ref();
        const uploadRef = storageRef.child(`user-uploads/${file.name}`);
        const uploadTask = uploadRef.put(file);

        return {
            uploadTask,
            uploadRef,
        }
    };

    static getRandomHomes = () => {

        const apiCompletionPromise = request({
            method: 'get',
            url: `${Environment.apiUrl}/homes/random/`,
            });

        return apiCompletionPromise;
    }


}
